<div *ngIf="circleCenter" class="map-container">
  <google-map
    height="500px"
    width="100%"
    [center]="circleCenter"
    [zoom]="zoom"
    [options]="mapOptions"
  >
    <div *ngIf="circleOptions.center && circleOptions.radius">
      <map-circle
        [center]="circleOptions.center"
        [radius]="circleOptions.radius"
        [options]="circleOptions"
      ></map-circle>
    </div>
    <map-marker [options]="markerOptions"></map-marker>
  </google-map>
</div>
