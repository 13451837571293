<div style="margin-bottom: 30px; display: flex; justify-content: center">
  <img src="assets/images/neurun-logo.svg" alt="Neurun logo" />
</div>
<div style="display: flex; align-items: center; flex-direction: column">
  <div *ngIf="isSuccess" style="margin-bottom: 16px">
    <img ngSrc="assets/images/celebration.svg" alt="Celebration" height="40" width="40"/>
  </div>
  <p *ngIf="title" style="font-weight: 600; font-size: 17px">{{ title }}</p>
  <p *ngIf="subTitle" style="font-weight: 500; font-size: 15px; text-align: center">{{ subTitle }}</p>
  <p *ngIf="subTitle2" style="font-weight: 500; font-size: 15px; text-align: center" [innerHTML]="subTitle2"></p>
</div>
