<div *ngIf="races.length > 0; else noRacesTemplate">
  <div *ngFor="let race of races" class="swipe-container">
    <div
      class="swipe-element"
      (touchstart)="onTouchStart($event)"
      (touchend)="onTouchEnd($event, race?.guid)"
      (click)="onRaceClick(race?.guid)"
    >
      <div class="race-item">
        <div class="race-date">
          <span>{{ race.starting_at | date: 'EEE' }}</span>
          <span>{{ race.starting_at | date: 'd' }}</span>
        </div>
        <div class="race-details">
          <h2>{{ race.name }}</h2>
          <p>{{ race.starting_at | date: time_format }} &bull; {{ race.location }}</p>
          <div class="action right">
            <img ngSrc="assets/images/delete-icon.svg" alt="Delete" height="24" width="25"/>
            <span style="font-weight: 500; font-size: 14px">Remove</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noRacesTemplate>
  <div class="no-races-message">There are no races</div>
</ng-template>
