import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from "../../../services/user/user.service";
import { User, UserNameInfo } from "../../../../types/models";
import { HeaderComponent } from "../../../components/header/header.component";
import { Router } from "@angular/router";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { CustomButtonComponent } from "../../../components/custom-button/custom-button.component";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { SpinnerServiceService } from "../../../services/spinner-service/spinner-service.service";
import { AsyncPipe, NgIf } from "@angular/common";
import { ScreenResizeService } from "../../../services/screenResive/screen-resize.service";

@Component({
  selector: 'app-user-name',
  standalone: true,
  imports: [
    HeaderComponent,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    CustomButtonComponent,
    MatProgressSpinner,
    AsyncPipe,
    NgIf
  ],
  templateUrl: './user-name.component.html',
  styleUrl: './user-name.component.scss'
})
export class UserNameComponent implements OnInit{
  user: User | undefined = undefined
  initialFormValues: any;
  isMobile: boolean = false;

  constructor(private userService: UserService, private router: Router, public spinnerService: SpinnerServiceService, private screenSizeService: ScreenResizeService) {}

  userNameForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    displayName: new FormControl(''),
  })

  async ngOnInit() {
    this.screenSizeService.getIsMobile().subscribe(isMobile => {
      this.isMobile = isMobile
    })

    this.userService.getUser().subscribe(response => {
      this.user = response

      this.userNameForm.get('firstName')?.setValue(response?.first_name || '');
      this.userNameForm.get('lastName')?.setValue(response?.last_name || '');
      this.userNameForm.get('displayName')?.setValue(response?.display_name || '');

      this.initialFormValues = this.userNameForm.value;
    })
  }

  hasFormChanged(): boolean {
    return JSON.stringify(this.initialFormValues) !== JSON.stringify(this.userNameForm.value);
  }

  onBackClick() {
    this.router.navigate(['/profile'])
  }

  onSubmit() {
    const data: UserNameInfo = {
      first_name: this.userNameForm.value.firstName ?? '',
      last_name: this.userNameForm.value.lastName ?? '',
    }

    if (this.userNameForm.value.displayName) {
      data.display_name = this.userNameForm.value.displayName ?? '';
    }

    this.userService.updateUser(data).subscribe(() => {
      this.router.navigate(['/profile'])
    })
  }
}
