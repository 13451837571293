import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { provideNgxMask } from 'ngx-mask';
import { OidcServiceService } from "../../services/oidcService/oidc-service.service";

@Component({
  selector: 'app-auth-callback',
  standalone: true,
  imports: [
  ],
  templateUrl: './auth-callback.component.html',
  providers: [provideNgxMask()],
})
export class AuthCallbackComponent implements OnInit {
  constructor(private readonly oidcService: OidcServiceService, private router: ActivatedRoute) {}

  async ngOnInit() {
    this.router.queryParams.subscribe(params => {
        const code = params['code'];

        this.oidcService.getNewAccessToken(code).subscribe((data) => {

          if (data?.token) {
            window.localStorage.setItem(`${data?.provider}_token`, JSON.stringify({ token: data?.token, updated_at: Date.now(), refresh_token: data?.refresh_token }));
          } else {
            window.localStorage.removeItem(`${data?.provider}_token`);
          }
          setTimeout(() => {
            window.close();
          }, 100)
        })
      }
    )
  }
}
