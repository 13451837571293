<div class="page-container">
  <app-header title="Notifications" (handleBackClick)="onBackClick()"></app-header>
  <div class="form-container">
    <div>
      <div class="title-container">
        <p class="section-title">Notification Category</p>
        <div class="toggle-switch">
          <input type="checkbox" id="switch" [(ngModel)]="notification1" (change)="onToggle()" />
          <label for="switch">Toggle</label>
        </div>
      </div>
      <div class="card">
        <div class="item">
          <div class="setting-description">
            <span class="subtitle">Item</span>
          </div>
          <div style="width: 50%">
            <mat-select [(value)]="selectedOption">
              <mat-option *ngFor="let option of options" [value]="option">{{option}}</mat-option>
            </mat-select>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="item">
          <div class="setting-description">
            <span class="subtitle">Item</span>
          </div>
          <div style="width: 50%">
            <mat-select [(value)]="selectedOption">
              <mat-option *ngFor="let option of options" [value]="option">{{option}}</mat-option>
            </mat-select>
          </div>
        </div>
      </div>
      <div style="margin-top: 24px">
        <div class="title-container">
          <p class="section-title">Notification Category</p>
          <div class="toggle-switch">
            <input type="checkbox" id="switch2" [(ngModel)]="notification2" (change)="onToggle()" />
            <label for="switch2">Toggle</label>
          </div>
        </div>
        <div class="card">
          <div class="item">
            <div class="setting-description">
              <span class="subtitle">Item</span>
            </div>
            <div style="width: 50%">
              <mat-select [(value)]="selectedOption">
                <mat-option *ngFor="let option of options" [value]="option">{{option}}</mat-option>
              </mat-select>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="item">
            <div class="setting-description">
              <span class="subtitle">Item</span>
            </div>
            <div style="width: 50%">
              <mat-select [(value)]="selectedOption">
                <mat-option *ngFor="let option of options" [value]="option">{{option}}</mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="submit-button-container">
      <app-custom-button type="submit" title="Save" variant="primary" [disabled]="true"></app-custom-button>
    </div>
  </div>
</div>
