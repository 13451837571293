import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Race } from "../../../../types/models";
import { MatButton } from "@angular/material/button";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import moment from "moment-timezone";
import { Router } from "@angular/router";
import { MatDivider } from "@angular/material/divider";

@Component({
  selector: 'app-calendar-view',
  standalone: true,
  imports: [
    MatButton,
    DatePipe,
    NgForOf,
    MatDivider,
    NgIf
  ],
  templateUrl: './calendar-view.component.html',
  styleUrl: './calendar-view.component.scss'
})
export class CalendarViewComponent implements OnInit, OnChanges {
  @Input() currentDate: Date = new Date();
  @Input() races: Race[] = [];

  weeks: Array<Array<{ day: number | null, races: Race[], isCurrentMonth: boolean }>> = [];
  dayNames: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  constructor(private router: Router) {}

  ngOnInit() {
    this.generateMonthDays()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentDate'] && !changes['currentDate'].isFirstChange()) {
      this.generateMonthDays()
    }
  }

  generateMonthDays() {
    const startOfMonth = moment(this.currentDate).startOf('month');
    const endOfMonth = moment(this.currentDate).endOf('month');
    const daysInMonth = endOfMonth.date();

    const monthDays = Array.from({ length: daysInMonth }, (_, i) => {
      const day = i + 1;
      const races = this.races.filter(race => {
        return moment(race.starting_at).isSame(moment(startOfMonth).date(day), 'day')
      });

      return { day, races, isCurrentMonth: true };
    });

    const firstDayOfWeek = startOfMonth.day();
    if (firstDayOfWeek > 0) {
      const prevMonthDays = Array.from({ length: firstDayOfWeek }, (_, i) => {
        const day = moment(startOfMonth).subtract(firstDayOfWeek - i, 'days').date();
        return { day, races: [], isCurrentMonth: false };
      });
      monthDays.unshift(...prevMonthDays);
    }

    this.weeks = [];
    for (let i = 0; i < monthDays.length; i += 7) {
      this.weeks.push(monthDays.slice(i, i + 7));
    }
  }

  onRaceClick(race_id: string | undefined) {
    this.router.navigate(['my-races/race', race_id])
  }
}
