<div class="my-races-header">
  <div style="display: flex; justify-content: space-between">
    <h1 class="title">My Races</h1>
    <div>
      <button mat-icon-button (click)="toggleView()" [ngStyle]="{'opacity': isListView ? 1 : 0.5}">
        <img src="assets/images/calendar-view-day.svg" alt="Calendar view day" />
      </button>
      <button mat-icon-button (click)="toggleView()" [ngStyle]="{'opacity': !isListView ? 1 : 0.5}">
        <img src="assets/images/calendar-view-month.svg" alt="Calendar view month" />
      </button>
    </div>
  </div>
<app-search-input (searchChange)="onSearchChange($event)" placeholder="Search your races" [value]="searchText"></app-search-input>
</div>
<div class="my-races-content">
  <button mat-button (click)="previousMonth()">
    <img src="assets/images/arrow-back.svg" alt="Arrow back" />
  </button>
  <span>{{ currentDate | date: 'MMMM yyyy' }}</span>
  <button mat-button (click)="nextMonth()">
    <img src="assets/images/arrow-forward.svg" alt="Arrow forward" />
  </button>
</div>
<div *ngIf="isListView" class="list-container">
  <app-list-view [races]="races" (handleRemoveRace)="handleDeleteRace($event)" [time_format]="time_format"></app-list-view>
</div>
<div *ngIf="!isListView" class="list-container">
  <app-calendar-view [currentDate]="currentDate" [races]="races"></app-calendar-view>
</div>
<button class="add-race-button" (click)="addRace()">
  <mat-icon>add</mat-icon>
  <span style="font-weight: 500; font-size: 16px">Add a race</span>
</button>
