import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'temperatureConverter',
  standalone: true
})
export class TemperatureConverterPipe implements PipeTransform {

  transform(value: number, unit: string): string {
    if (unit === 'metric') {
      const celsius = (value - 32) * 5 / 9
      return `${celsius.toFixed(0)} °C`;
    } else if (unit === 'imperial') {
      const fahrenheit = (value * 9/5) + 32;
      return `${fahrenheit.toFixed(0)} °F`;
    } else {
      return `${value} (unknown unit)`;
    }
  }

}
