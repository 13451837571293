import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {provideNativeDateAdapter} from '@angular/material/core';

@Component({
  selector: 'app-date-field',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './date-field.component.html',
  styleUrl: './date-field.component.scss'
})
export class DateFieldComponent {
  value: string = '';
  @Input() placeholder: string = ''
  @Input() label: string = ''
  @Output() handleChange = new EventEmitter<string>();

  onChange() {
    this.handleChange.emit(this.value)
  }
}
