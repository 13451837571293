import { Component, OnInit } from '@angular/core';
import { ListViewComponent } from "./list-view/list-view.component";
import { MatButton, MatFabButton, MatIconButton } from "@angular/material/button";
import { AsyncPipe, DatePipe, NgIf, NgStyle } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { SearchInputComponent } from "../../components/search-input/search-input.component";
import { ActivatedRoute, Router } from '@angular/router';
import { GetRacePayload, Race } from "../../../types/models";
import { debounceTime, distinctUntilChanged, Subject } from "rxjs";
import { CalendarViewComponent } from "./calendar-view/calendar-view.component";
import moment from "moment-timezone";
import { RaceService } from "../../services/race/race.service";
import { KeycloakService } from "keycloak-angular";
import {USER_UNIT_SYSTEM} from "../../../constants";

@Component({
  selector: 'app-my-races',
  standalone: true,
  imports: [
    ListViewComponent,
    MatIconButton,
    DatePipe,
    MatButton,
    MatFabButton,
    MatIcon,
    SearchInputComponent,
    NgStyle,
    NgIf,
    CalendarViewComponent,
    AsyncPipe
  ],
  templateUrl: './my-races.component.html',
  styleUrls: ['./my-races.component.scss'],
})
export class MyRacesComponent implements OnInit {
  private _currentDate: Date = new Date();
  private _searchText: string = ''
  private isInternalDateChange: boolean = false;
  private isInternalSearchTextChange: boolean = false;
  time_format: string = 'HH:mm';

  get currentDate(): Date {
    return this._currentDate;
  }

  get searchText(): string {
    return this._searchText;
  }

  set currentDate(value: Date) {
    this._currentDate = value;
    if (!this.isInternalDateChange) {
      this.onCurrentDateChange();
    }
  }

  set searchText(value: string) {
    this._searchText = value;
    if (!this.isInternalSearchTextChange) {
      this.onSearchTextChange();
    }
  }

  isListView: boolean = true;
  races: Race[] = [];
  private searchText$ = new Subject<string>();

  constructor(private router: Router, private route: ActivatedRoute, private raceService: RaceService, private readonly keycloakService: KeycloakService) {
    this.currentDate = new Date();
  }

  async ngOnInit() {
    const isTokenExpired = this.keycloakService.isTokenExpired();

    if (isTokenExpired) {
      await this.keycloakService.login()
    }

    const userUnitSystem = localStorage.getItem(USER_UNIT_SYSTEM);
    const parsedUserUnitSystem = (userUnitSystem && JSON.parse(userUnitSystem)) || 'metric';

    if (parsedUserUnitSystem === 'imperial') {
      this.time_format = 'shortTime'
    } else {
      this.time_format = 'HH:mm';
    }

    const calendarView = this.route.snapshot.paramMap.get('calendarView') || 0;

    const givenDate = moment(this.currentDate);
    const startDate = givenDate.startOf('month').format('YYYY-MM-DD');
    const endDate = givenDate.endOf('month').format('YYYY-MM-DD');
    const data: GetRacePayload = {};

    data.startDate = startDate;
    data.endDate = endDate;

    this.getRaces(data);

    this.isListView = calendarView !== '1';

    this.searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      this.searchText = value;
    });
  }

  onCurrentDateChange() {
    if (!this.currentDate) return;
    if (this.searchText) return;

    const givenDate = moment(this.currentDate);
    const startDate = givenDate.startOf('month').format('YYYY-MM-DD');
    const endDate = givenDate.endOf('month').format('YYYY-MM-DD');
    const data: GetRacePayload = {};

    data.startDate = startDate;
    data.endDate = endDate;

    this.getRaces(data);
  }

  onSearchTextChange() {
    const data: GetRacePayload = {};

    const givenDate = moment(this.currentDate);
    const startDate = givenDate.startOf('month').format('YYYY-MM-DD');
    const endDate = givenDate.endOf('month').format('YYYY-MM-DD');

    if (!this.currentDate) return;

    if (this.searchText) {
      data.search = this.searchText
    } else {
      data.startDate = startDate;
      data.endDate = endDate
    }

    this.getRaces(data)
  }

  getRacesFromCurrentMonth(events: Race[], date: string): Race[] {
    const targetMonth = moment(date).month();
    const targetYear = moment(date).year();

    return events.filter(event => {
      const eventDate = moment(event.starting_at);
      return eventDate.month() === targetMonth && eventDate.year() === targetYear;
    });
  }

  getRaces(data: GetRacePayload) {
    this.raceService.getRaces(data).subscribe(response => {
      if (response?.data?.length) {
        this.isInternalDateChange = true;  // Set the flag to true
        this.currentDate = new Date(response.data[0].starting_at);
        this.isInternalDateChange = false; // Reset the flag to false

        const filteredRaces = this.getRacesFromCurrentMonth(response?.data, response.data[0].starting_at);

        const sortedRace = filteredRaces?.sort((a, b) => {
          const dateA = new Date(a?.starting_at).getTime();
          const dateB = new Date(b?.starting_at).getTime();
          return dateA - dateB;
        });
        this.races = sortedRace;
      } else {
        this.races = [];
      }
    });
  }

  toggleView() {
    this.isListView = !this.isListView;
  }

  previousMonth() {
    this.isInternalSearchTextChange = true;
    this.searchText = ''
    this.currentDate = new Date(this.currentDate.setMonth(this.currentDate.getMonth() - 1));
    this.isInternalSearchTextChange = false;
  }

  nextMonth() {
    this.isInternalSearchTextChange = true;
    this.searchText = ''
    this.currentDate = new Date(this.currentDate.setMonth(this.currentDate.getMonth() + 1));
    this.isInternalSearchTextChange = false;
  }

  onSearchChange(searchText: string) {
    this.searchText$.next(searchText);
  }

  handleDeleteRace(raceId: string) {
    this.raceService.deleteRace(raceId).subscribe(() => {
      const data: GetRacePayload = {};
      const givenDate = moment(this.currentDate);
      const startDate = givenDate.startOf('month').format('YYYY-MM-DD');
      const endDate = givenDate.endOf('month').format('YYYY-MM-DD');

      if (!this.currentDate) return;

      if (this.searchText) {
        data.search = this.searchText
      } else {
        data.startDate = startDate;
        data.endDate = endDate
      }

      this.getRaces(data)
    });
  }

  addRace() {
    this.router.navigate(['/find-a-race']);
  }
}
