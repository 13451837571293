import {Component, Input} from '@angular/core';
import {SocialLoginComponent} from "../social-login/social-login.component";
import {NgIf, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-auth-header',
  standalone: true,
  imports: [
    SocialLoginComponent,
    NgIf,
    NgOptimizedImage
  ],
  templateUrl: './auth-header.component.html',
  styleUrl: './auth-header.component.scss'
})
export class AuthHeaderComponent {
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() subTitle2: string = '';
  @Input() isSuccess: boolean = false;
}
