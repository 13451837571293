<div class="container">
  <div *ngIf="currentStep === 1">
    <app-auth-header title="Create an account" subTitle="Sign up for a free account to use Neurun AI, save content, and customize your profile and settings."></app-auth-header>
    <div style="margin-top: 20px; margin-bottom: 24px">
      <app-social-login></app-social-login>
    </div>
    <div class="divider-container">
      <div class="line"><mat-divider class="divider"></mat-divider></div>
      <div class="divider-text">or</div>
      <div class="line"><mat-divider class="divider"></mat-divider></div>
    </div>
    <div style="margin-top: 24px">
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div>
          <span class="input-label">Email Address</span>
          <mat-form-field>
            <input matInput autocomplete="off" id="email" formControlName="email" placeholder="example@neurun.com">
          </mat-form-field>
        </div>
        <div style="margin-top: 40px; margin-bottom: 16px">
          <app-custom-button type="submit" title="Next" variant="primary" [disabled]="!registerForm.valid"></app-custom-button>
        </div>
      </form>
      <span style="font-weight: 500; text-align: center; display: flex; align-items: center; justify-content: center; gap: 4px;">Already have an account? <span class="action-button" (click)="onLoginClick()">Login here</span></span>
      <div style="margin-top: 30px; text-align: center">
      <span>
        By creating an account via Facebook, Google, Apple, or email, I agree to <a href="https://www.formula1.com/en/racing/2024" target=”_blank” >Neurun’s Terms</a> of <a href="https://www.formula1.com/en/racing/2024" target=”_blank” >Service and Privacy Policy</a>.
      </span>
      </div>
    </div>
  </div>
  <div *ngIf="currentStep === 2" style="width: 100%; height: 100vh; position: relative">
    <app-auth-header title="Welcome! Let’s get to know you"></app-auth-header>
    <div style="margin-top: 24px">
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div>
          <span class="input-label">Name</span>
          <mat-form-field>
            <input matInput autocomplete="off" id="name" formControlName="name">
          </mat-form-field>
        </div>
        <div style="margin-top: 40px; margin-bottom: 16px; position: absolute; bottom: 30px; width: 100%">
          <app-custom-button type="submit" title="Next" variant="primary" [disabled]="!registerForm.valid"></app-custom-button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="currentStep === 3" style="width: 100%; height: 100vh; position: relative">
    <app-auth-header title="Create a password"></app-auth-header>
    <div style="margin-top: 24px">
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div>
          <span class="input-label">Login Password</span>
          <mat-form-field>
            <input matInput autocomplete="off" id="password" formControlName="password">
          </mat-form-field>
        </div>
        <div>
          <p style="font-weight: 500; font-size: 16px">Your password must contain:</p>
          <div class="list-container">
            <div class="list-item">
              <img *ngIf="passwordCriteria.hasMinLength; else emptyBlock" ngSrc="assets/images/check.svg" alt="Check"
                   height="24" width="24"/>
              8-32 characters long
            </div>
            <div class="list-item">
              <img *ngIf="passwordCriteria.hasLowerCase; else emptyBlock" ngSrc="assets/images/check.svg" alt="Check"
                   height="24" width="24"/>
              1 lowercase character (a-z)
            </div>
            <div class="list-item">
              <img *ngIf="passwordCriteria.hasUpperCase; else emptyBlock" ngSrc="assets/images/check.svg" alt="Check"
                   height="24" width="24"/>
              1 uppercase character (A-Z)
            </div>
            <div class="list-item">
              <img *ngIf="passwordCriteria.hasNumeric; else emptyBlock" ngSrc="assets/images/check.svg" alt="Check"
                   height="24" width="24"/>
              1 number
            </div>
            <div class="list-item">
              <img *ngIf="passwordCriteria.hasSpecial; else emptyBlock" ngSrc="assets/images/check.svg" alt="Check"
                   height="24" width="24"/>
              1 special character e.g. !&#64;#$%
            </div>
          </div>
        </div>
        <ng-template #emptyBlock>
          <div style="width: 24px; height: 24px"></div>
        </ng-template>
        <div style="margin-top: 40px; margin-bottom: 16px; position: absolute; bottom: 30px; width: 100%">
          <app-custom-button type="submit" title="Create Account" variant="primary" [disabled]="!registerForm.valid"></app-custom-button>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="currentStep === 4" style="width: 100%; height: 100vh; position: relative">
    <app-auth-header
      title="You’re in!"
      subTitle="You now have access to all of Neurun’s personalized insight tools."
      [subTitle2]="successMessage"
      [isSuccess]="true"
    ></app-auth-header>
    <div style="margin-top: 40px; margin-bottom: 16px; position: absolute; bottom: 30px; width: 100%">
      <app-custom-button type="submit" title="Start Exploring" variant="primary"></app-custom-button>
    </div>
  </div>
</div>
