<span *ngIf="startPoint && endPoint">
  <google-map
    height="400px"
    width="350px"
    [mapTypeId]="mapTypeId"
    [center]="center"
    [zoom]="zoom"
    (mapClick)="addMarker($event)">
  @for (position of markerPositions; track position) {
    <map-marker [position]="position" [options]="markerOptions" />
  }
</google-map>
</span>
