import { Component } from '@angular/core';
import {MatFormField, MatOption, MatSelect} from "@angular/material/select";
import {MatDivider} from "@angular/material/divider";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {FormsModule} from "@angular/forms";
import {AsyncPipe, NgForOf, NgIf, Location} from "@angular/common";
import {MatCard} from "@angular/material/card";
import {HeaderComponent} from "../../../components/header/header.component";
import {UserService} from "../../../services/user/user.service";
import {Router} from "@angular/router";
import {SpinnerServiceService} from "../../../services/spinner-service/spinner-service.service";
import {CustomButtonComponent} from "../../../components/custom-button/custom-button.component";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@Component({
  selector: 'app-user-ai-settings',
  standalone: true,
  imports: [
    MatSelect,
    MatOption,
    MatDivider,
    MatSlideToggle,
    FormsModule,
    NgForOf,
    MatCard,
    AsyncPipe,
    HeaderComponent,
    NgIf,
    CustomButtonComponent,
    MatSlideToggleModule,
    MatFormField
  ],
  templateUrl: './user-ai-settings.component.html',
  styleUrl: './user-ai-settings.component.scss'
})
export class UserAiSettingsComponent {
  voices: string[] = ['Jesse', 'Alex', 'Sam'];
  languages: string[] = ['Auto Detect', 'English', 'Spanish', 'French'];
  detailLevels: string[] = ['Low', 'Medium', 'High'];

  selectedVoice = this.voices[0];
  selectedLanguage = this.languages[0];
  selectedDetailLevel = this.detailLevels[1];
  memoryEnabled = true;

  constructor(private userService: UserService, private router: Router, public spinnerService: SpinnerServiceService, private location: Location) {}

  onBackClick() {
    this.location.back()
  }

  selectIsOpen() {
  }

  onToggle() {
    console.log('Toggle switch state:', this.memoryEnabled);
  }
}
