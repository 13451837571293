import {Component, OnDestroy, OnInit} from '@angular/core';
import {AsyncPipe, NgClass, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {CustomButtonComponent} from "../../../components/custom-button/custom-button.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HeaderComponent} from "../../../components/header/header.component";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {UserService} from "../../../services/user/user.service";
import {Router} from "@angular/router";
import {SpinnerServiceService} from "../../../services/spinner-service/spinner-service.service";
import {MatCard} from "@angular/material/card";
import {MatDivider} from "@angular/material/divider";
import {OidcServiceService} from "../../../services/oidcService/oidc-service.service";

interface ConnectedAccounts {
  logo: string;
  name: string;
  status: string;
  connected: boolean;
  providerName: string;
}


@Component({
  selector: 'app-user-accounts',
  standalone: true,
  imports: [
    AsyncPipe,
    CustomButtonComponent,
    FormsModule,
    HeaderComponent,
    MatFormField,
    MatInput,
    NgIf,
    ReactiveFormsModule,
    NgOptimizedImage,
    MatCard,
    NgForOf,
    NgClass,
    MatDivider
  ],
  templateUrl: './user-accounts.component.html',
  styleUrl: './user-accounts.component.scss'
})
export class UserAccountsComponent implements OnInit, OnDestroy {
  private runkeeperToken: string = '';
  private stravaToken: string = '';
  private hasRunkeeperTokenProcessed = false;
  private hasStravaTokenProcessed = false;
  private currentRunkeeperAccount: ConnectedAccounts | null = null;
  private currentStravaAccount: ConnectedAccounts | null = null;
  private stravaRefreshToken: string = '';
  integrations: ConnectedAccounts[] = [
    // { logo: 'assets/images/asics-logo.svg', name: 'ASICS Runkeeper', status: 'Connected', connected: false },
    // { logo: 'assets/images/apple-logo.svg', name: 'Apple Health', status: 'Not Connected', connected: false },
    // { logo: 'assets/images/garmin-logo.svg', name: 'Garmin', status: 'Not Connected', connected: false },
    // { logo: 'assets/images/fitbit-logo.svg', name: 'Fitbit', status: 'Not Connected', connected: false },
    // { logo: 'assets/images/strava-logo.svg', name: 'Strava', status: 'Not Connected', connected: false },
    // { logo: 'assets/images/samsung-health-logo.svg', name: 'Samsung Health', status: 'Not Connected', connected: false }
  ];

  constructor(private userService: UserService, private router: Router, public spinnerService: SpinnerServiceService, private readonly oidcService: OidcServiceService) {}

  ngOnInit() {
    const runkeeperData = localStorage.getItem('runkeeper_token') || '';
    const stravaData = localStorage.getItem('strava_token') || '';
    let isRunkeeperAccountConnected = false;
    let isStravaAccountConnected = false;
    if (runkeeperData) {
      const parsedData = JSON.parse(runkeeperData);
      this.runkeeperToken = parsedData.token.replace('Bearer ', '');
      isRunkeeperAccountConnected = true;
    }
    if (stravaData) {
      const parsedData = JSON.parse(stravaData);
      this.stravaToken = parsedData.token.replace('Bearer ', '');
      this.stravaRefreshToken = parsedData?.refresh_token;
      isStravaAccountConnected = true;
    }
    this.integrations.push({ logo: 'assets/images/asics-logo.svg', name: 'ASICS Runkeeper', status: isRunkeeperAccountConnected ? 'Connected' : 'Not Connected', providerName: 'runkeeper', connected: isRunkeeperAccountConnected });
    this.integrations.push({ logo: 'assets/images/strava-logo.svg', name: 'Strava', status: isStravaAccountConnected ? 'Connected' : 'Not Connected', providerName: 'strava', connected: isStravaAccountConnected });

    window.addEventListener('storage', (e) => this.onRunkeeperToken(e))
  }

  onRunkeeperToken(event: StorageEvent) {
    if (event.storageArea !== localStorage) return
    if (event.key === 'runkeeper_token' && event.newValue) {
      if (this.hasRunkeeperTokenProcessed) return;
      const runkeeperData = JSON.parse(event.newValue);
      if (runkeeperData.token) {
        this.runkeeperToken = runkeeperData.token.replace('Bearer ', '');
        if (this.currentRunkeeperAccount) {
          this.changeStatus(this.currentRunkeeperAccount);
        }
        this.hasRunkeeperTokenProcessed = true;
      }
      window.removeEventListener('storage', this.onRunkeeperToken)
    }
    if (event.key === 'strava_token' && event.newValue) {
      if (this.hasStravaTokenProcessed) return;
      const stravaData = JSON.parse(event.newValue);
      if (stravaData.token) {
        this.stravaToken = stravaData.token.replace('Bearer ', '');
        this.stravaRefreshToken = stravaData.refresh_token;
        if (this.currentStravaAccount) {
          this.changeStatus(this.currentStravaAccount);
        }
        this.hasStravaTokenProcessed = true;
      }
      window.removeEventListener('storage', this.onRunkeeperToken)
    }
  }

  ngOnDestroy() {
    window.removeEventListener('storage', this.onRunkeeperToken);
  }

  changeStatus(integration: ConnectedAccounts) {
    integration.connected = !integration.connected;
    integration.status = integration.connected ? 'Connected' : 'Not Connected'
  }

  toggleConnection(integration: ConnectedAccounts) {
    if (integration.providerName === 'runkeeper') {
      if (this.runkeeperToken) {
        this.oidcService.logout(this.runkeeperToken, 'runkeeper').subscribe(() => {
          localStorage.removeItem('runkeeper_token');
          this.changeStatus(integration);
          this.currentRunkeeperAccount = integration;
          this.runkeeperToken = ''
        })
      } else {
        this.oidcService.openAuthWindow('runkeeper');
        this.currentRunkeeperAccount = integration;
      }
    }

    if (integration.providerName === 'strava') {
      if (this.stravaToken) {
        // @ts-ignore
        this.oidcService.logoutStrava(this.stravaRefreshToken).subscribe(() => {
          localStorage.removeItem('strava_token');
          this.changeStatus(integration);
          this.currentStravaAccount = integration;
          this.stravaToken = ''
        })
      } else {
        this.oidcService.openAuthWindow('strava');
        this.currentStravaAccount = integration;
      }
    }
  }

  onBackClick() {
    this.router.navigate(['/profile'])
  }
}
