<div>
  <app-header title="Connected Accounts" (handleBackClick)="onBackClick()"></app-header>
  <div *ngIf="spinnerService.visibility | async; else content" class="loading">
    <div class="loader"></div>
  </div>
  <ng-template #content>
    <div style="height: calc(100vh - 160px); display: flex; flex-direction: column">
      <div style="padding: 24px 16px">
        <div class="integration-container">
          <div *ngFor="let integration of integrations">
            <div class="integration-content">
              <div class="integration-info">
                <div style="min-width: 95px; text-align: center">
                  <img [src]="integration.logo" alt="{{ integration.name }}" class="integration-logo">
                </div>
                <div class="integration-details">
                  <div>{{ integration.name }}</div>
                  <div [ngClass]="integration.connected ? 'connected' : 'not-connected'">{{ integration.status }}</div>
                </div>
              </div>
              <span class="action-button" (click)="toggleConnection(integration)">{{ integration.connected ? 'Disconnect' : 'Connect' }}</span>
            </div>
            <mat-divider></mat-divider>
          </div>
        </div>
      </div>
<!--      <div style="margin-top: auto; padding: 0 16px">-->
<!--        <app-custom-button type="submit" title="Save" variant="primary" [disabled]="true"></app-custom-button>-->
<!--      </div>-->
    </div>
  </ng-template>
</div>
