import { Component } from '@angular/core';
import {AuthHeaderComponent} from "../../components/auth-header/auth-header.component";
import {SocialLoginComponent} from "../../components/social-login/social-login.component";
import {MatDivider} from "@angular/material/divider";
import {CustomButtonComponent} from "../../components/custom-button/custom-button.component";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {Router} from "@angular/router";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {MatList, MatListItem} from "@angular/material/list";
import {KeycloakAuth} from "../../services/keycloak/keycloak.service";
import {EMAIL_REGEX} from "../../../constants";

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    AuthHeaderComponent,
    SocialLoginComponent,
    MatDivider,
    CustomButtonComponent,
    FormsModule,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    NgIf,
    MatList,
    MatListItem,
    NgOptimizedImage
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent {
  currentStep: number = 1;
  registerForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
    name: new FormControl(''),
    password: new FormControl('')
  })

  constructor(private router: Router, private readonly authKeycloak: KeycloakAuth) {}

  onSubmit() {
    this.authKeycloak.register('test', 'test', 'test22@mail.com').subscribe(res => {
    })
    this.currentStep += 1;
    this.updateValidators();
  }

  updateValidators() {
    const emailControl = this.registerForm.get('email');
    const nameControl = this.registerForm.get('name');
    const passwordControl = this.registerForm.get('password');

    if (this.currentStep === 2) {
      emailControl?.clearValidators();
      nameControl?.setValidators([Validators.required])
    }

    if (this.currentStep === 3) {
      emailControl?.clearValidators();
      nameControl?.clearValidators();
      passwordControl?.setValidators([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(32),
        this.passwordValidator
      ])
    }
  }

  passwordValidator(control: any) {
    const value = control.value;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumeric = /[0-9]/.test(value);
    const hasSpecial = /[!@#\$%\^\&*\)\(+=._-]+/.test(value);

    const valid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecial;
    if (!valid) {
      return { passwordInvalid: true };
    }
    return null;
  }

  get password() {
    return this.registerForm.get('password');
  }

  get successMessage() {
    const email = this.registerForm.value.email;
    return `A confirmation email has been sent to <b>${email}</b> with all of your account details.`;
  }

  get passwordCriteria() {
    const value = this.password?.value || '';
    return {
      hasMinLength: value.length >= 8 && value.length <= 32,
      hasLowerCase: /[a-z]/.test(value),
      hasUpperCase: /[A-Z]/.test(value),
      hasNumeric: /[0-9]/.test(value),
      hasSpecial: /[!@#\$%\^\&*\)\(+=._-]+/.test(value),
    };
  }

  onLoginClick() {
    this.router.navigate(['/login'])
  }
}
