import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AsyncPipe } from "@angular/common";
import { Loader } from '@googlemaps/js-api-loader';
import { GpxWaypoints } from "../../../types/models";


interface LatLng {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-map-direction',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './map-direction.component.html',
  styleUrl: './map-direction.component.scss'
})
export class MapDirectionComponent implements OnChanges{
  @Input() startPoint: LatLng | null = null;
  @Input() endPoint: LatLng | null = null;
  @Input() path: LatLng[] = [];
  @Input() waypoints: GpxWaypoints[] | null = [];
  @Input() raceUnit: string = 'metric';
  private startIcon: string = ''
  private finishIcon: string = ''
  private waterIcon: string = ''

  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef<HTMLDivElement>;

  map: google.maps.Map | null = null;
  line: google.maps.Polyline | null = null;
  isPlacingAidStation = false;

  constructor() {
    this.startIcon = '../../../assets/images/start-marker.svg'
    this.finishIcon = '../../../assets/images/finish-icon.svg'
    this.waterIcon = '../../../assets/images/water-marker.svg'
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['startPoint'] && this.startPoint && changes['endPoint'] && this.endPoint) {
      this.initializeMap();
    }
  }

  initializeMap() {
    const loader = new Loader({
      apiKey: 'YOUR_API_KEY',
      libraries: ['geometry'],
    });

    loader.load().then(() => {
      const mapOptions: google.maps.MapOptions = {
        center: new google.maps.LatLng(this.startPoint!.lat, this.startPoint!.lng),
        zoom: 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };

      this.map = new google.maps.Map(this.mapContainer.nativeElement, mapOptions);

      const lineCoordinates = [
        ...this.path.map((point) => new google.maps.LatLng(point.lat, point.lng)),
      ];

      this.map.setCenter(new google.maps.LatLng(this.startPoint!.lat, this.startPoint!.lng));

      this.line = new google.maps.Polyline({
        path: lineCoordinates,
        strokeColor: '#6271FF',
        strokeOpacity: 2.0,
        strokeWeight: 5,
        map: this.map,
      });

      const aidStationIcon = {
        url: this.waterIcon,
        scaledSize: new google.maps.Size(32, 32),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 32),
      };

      const startIcon = {
        url: this.startIcon,
        scaledSize: new google.maps.Size(42, 42),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 32),
      };

      const finishIcon = {
        url: this.finishIcon,
        scaledSize: new google.maps.Size(42, 42),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 32),
      };

      const startWaypointIndex = this.waypoints ? this.waypoints?.findIndex(i => i.properties.name === 'Start') : -1;

      let startPosition = new google.maps.LatLng(this.startPoint!.lat, this.startPoint!.lng);

      if (startWaypointIndex !== -1 && this.waypoints) {
        const coord = this.waypoints[startWaypointIndex].geometry.coordinates[0];
        startPosition = new google.maps.LatLng(coord.lat, coord.lon);
      }

      new google.maps.Marker({
        position: startPosition,
        map: this.map,
        icon: startIcon,
      });

      const finishWaypointIndex = this.waypoints ? this.waypoints?.findIndex(i => i.properties.name === 'Finish') : -1;

      let finishPosition = new google.maps.LatLng(this.endPoint!.lat, this.endPoint!.lng);

      if (finishWaypointIndex !== -1 && this.waypoints) {
        const coord = this.waypoints[finishWaypointIndex].geometry.coordinates[0];
        finishPosition = new google.maps.LatLng(coord.lat, coord.lon);
      }

      new google.maps.Marker({
        position: finishPosition,
        map: this.map,
        icon: finishIcon,
      });
      if (this.waypoints) {
        this.waypoints.forEach((waypoint) => {
          if (waypoint.properties.type !== 'DistanceMarker' && waypoint.properties.type !== 'Start' && waypoint.properties.type !== 'Finish') {
            const coordinates = waypoint.geometry.coordinates[0];
            new google.maps.Marker({
              position: new google.maps.LatLng(coordinates.lat, coordinates.lon),
              map: this.map,
              icon: aidStationIcon,
            });
          }
        });
      }

      this.placeDistanceMarkers(this.map, this.path);
    });
  }

  createKmMarkerIcon(km: number): google.maps.Icon {
    const desiredSize = 40;
    const devicePixelRatio = window.devicePixelRatio || 1;
    const canvasSize = desiredSize * devicePixelRatio;

    const canvas = document.createElement('canvas');
    canvas.width = canvasSize;
    canvas.height = canvasSize;
    const context = canvas.getContext('2d');

    if (context) {
      context.scale(devicePixelRatio, devicePixelRatio);

      context.beginPath();
      context.arc(desiredSize / 2, desiredSize / 2, 18, 0, 2 * Math.PI, false);
      context.fillStyle = '#4C57BC';
      context.fill();
      context.lineWidth = 3;
      context.strokeStyle = '#FFFFFF';
      context.stroke();

      context.fillStyle = '#FFFFFF';
      context.font = `${13 * (devicePixelRatio / 1)}px Arial`;
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(km.toString(), desiredSize / 2, desiredSize / 2);
    }

    return {
      url: canvas.toDataURL(),
      scaledSize: new google.maps.Size(desiredSize, desiredSize), // Set to desired CSS size
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(desiredSize / 2, desiredSize / 2),
    };
  }

  placeDistanceMarkers(map: google.maps.Map, path: LatLng[]) {
    const distancePerUnit = this.raceUnit === 'metric' ? 1000 : 1609.34;
    let distanceTraveled = 0;
    const sphericalLib = google.maps.geometry.spherical;
    let unitCount = 1;

    for (let i = 1; i < path.length; i++) {
      const segmentDistance = sphericalLib.computeDistanceBetween(
        new google.maps.LatLng(path[i - 1].lat, path[i - 1].lng),
        new google.maps.LatLng(path[i].lat, path[i].lng)
      );
      distanceTraveled += segmentDistance;

      if (distanceTraveled >= distancePerUnit) {
        const position = new google.maps.LatLng(path[i].lat, path[i].lng);

        new google.maps.Marker({
          position: position,
          map: map,
          icon: this.createKmMarkerIcon(unitCount),
          title: this.raceUnit === 'metric' ? `KM ${unitCount}` : `Mile ${unitCount}`,
        });

        unitCount++;
        distanceTraveled -= distancePerUnit;
      }
    }
  }

  handleMapClick(event: google.maps.MapMouseEvent) {
    if (this.isPlacingAidStation && event.latLng) {
      const aidStationIcon = {
        url: 'https://freesvg.org/img/pitr_First_aid_icon.png',
        scaledSize: new google.maps.Size(32, 32),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 32),
      };

      new google.maps.Marker({
        position: event.latLng,
        map: this.map!,
        icon: aidStationIcon,
      });

      this.isPlacingAidStation = false;
    }
  }

  handleAidStationButtonClick() {
    this.isPlacingAidStation = true;
  }
}
