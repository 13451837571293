<div>
  <app-header title="Manual Entry" (handleBackClick)="onBackClick()"></app-header>
  <div style="padding: 16px; height: calc(100vh - 160px); display: flex; flex-direction: column">
    <div class="container">
      <img ngSrc="../../../assets/images/celebration.svg" alt="Celebration" height="40" width="40"/>
      <span class="title">Success!</span>
      <span class="subtitle">
        “{{ title }}” has been added to your race calendar.
      </span>
    </div>
    <div class="button-container">
      <app-custom-button title="See Race Details" variant="primary" (handleClick)="onViewRaceDetails()"></app-custom-button>
      <app-custom-button title="View in Calendar" variant="secondary" (handleClick)="onViewCalendarCLick()"></app-custom-button>
    </div>
  </div>
</div>
