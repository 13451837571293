import { Component } from '@angular/core';
import {CustomButtonComponent} from "../../../components/custom-button/custom-button.component";
import {FormsModule} from "@angular/forms";
import {HeaderComponent} from "../../../components/header/header.component";
import {MatDivider} from "@angular/material/divider";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {NgForOf} from "@angular/common";
import {UserService} from "../../../services/user/user.service";
import {Router} from "@angular/router";
import {SpinnerServiceService} from "../../../services/spinner-service/spinner-service.service";

@Component({
  selector: 'app-user-notifications',
  standalone: true,
  imports: [
    CustomButtonComponent,
    FormsModule,
    HeaderComponent,
    MatDivider,
    MatOption,
    MatSelect,
    NgForOf
  ],
  templateUrl: './user-notifications.component.html',
  styleUrl: './user-notifications.component.scss'
})
export class UserNotificationsComponent {
  options: string[] = ['Email', 'Phone'];
  selectedOption = this.options[0];
  notification1 = true;
  notification2 = true;
  constructor(private userService: UserService, private router: Router, public spinnerService: SpinnerServiceService) {}

  onBackClick() {
    this.router.navigate(['/profile'])
  }

  onToggle() {}
}
