<div *ngIf="races.length > 0; else noRacesTemplate">
  <div class="calendar-weekdays">
    <div *ngFor="let day of dayNames" class="calendar-weekday">
      {{ day }}
    </div>
  </div>
  <div class="divider"></div>
  <div class="calendar-grid">
    <div class="calendar-week" *ngFor="let week of weeks">
      <div
        class="calendar-day"
        *ngFor="let day of week"
        [class.other-month]="!day.isCurrentMonth"
        [class.race-day]="day.races.length"
      >
        <div class="day-number">{{ day.day }}</div>
        <div class="races" *ngFor="let race of day.races">
          <div class="race" (click)="onRaceClick(race?.guid)">
            {{ race.name }}
          </div>
        </div>
      </div>
      <div class="divider"></div>
    </div>
  </div>
</div>
<ng-template #noRacesTemplate>
  <div class="no-races-message">There are no races</div>
</ng-template>
