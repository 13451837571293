import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { Coordinates } from "../../../types/models";
import { NgIf } from "@angular/common";

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [GoogleMap, MapMarker, NgIf],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent implements OnChanges{
  @Input() startPoint: Coordinates | null = null
  @Input() endPoint: Coordinates | null = null
  center: google.maps.LatLngLiteral = { lat: 12, lng: 12 };
  zoom = 10;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = []
  mapTypeId: google.maps.MapTypeId = google.maps.MapTypeId?.TERRAIN

  ngOnChanges(changes: SimpleChanges) {
    if (changes['startPoint'].currentValue && changes['endPoint'].currentValue) {
      const startPoint = changes['startPoint'].currentValue
      const endPoint = changes['endPoint'].currentValue

      this.center = startPoint;
      this.markerPositions = [startPoint, endPoint];
    }
  }

  addMarker(event: google.maps.MapMouseEvent) {
    if(!event.latLng) return
    this.markerPositions.push(event.latLng.toJSON());
  }

  protected readonly JSON = JSON;
}
