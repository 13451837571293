import { Injectable } from '@angular/core';
import {BehaviorSubject, debounceTime, fromEvent} from "rxjs";
import {SCREEN_SMALL_SIZE} from "../../../constants";

@Injectable({
  providedIn: 'root'
})
export class ScreenResizeService {
  private screenWidth = new BehaviorSubject<number>(window.innerWidth);
  private screenHeight = new BehaviorSubject<number>(window.innerHeight);
  private isMobile = new BehaviorSubject<boolean>(window.innerWidth <= SCREEN_SMALL_SIZE);

  constructor() {
    // Listen for window resize and update values
    fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.screenWidth.next(window.innerWidth);
        this.screenHeight.next(window.innerHeight);
        this.isMobile.next(window.innerWidth <= SCREEN_SMALL_SIZE);
      });
  }

  // Expose observables to track screen changes
  getScreenWidth() {
    return this.screenWidth.asObservable();
  }

  getScreenHeight() {
    return this.screenHeight.asObservable();
  }

  getIsMobile() {
    return this.isMobile.asObservable();
  }
}
