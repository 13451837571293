<div class="container">
  <div>
    <button class="auth-button" (click)="loginWithProvider('facebook')">
      <img src="assets/images/facebook-logo.svg" alt="Facebook" />
    </button>
  </div>
  <div>
    <button class="auth-button" (click)="loginWithProvider('google')">
      <img src="assets/images/google-logo.svg" alt="Google" />
    </button>
  </div>
  <div>
    <button class="auth-button" (click)="loginWithProvider('apple')">
      <img src="assets/images/apple-logo.svg" alt="Apple Inc." />
    </button>
  </div>
</div>
