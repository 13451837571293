import {Component, Input, OnInit} from '@angular/core';
import {GoogleMap, MapCircle, MapMarker} from "@angular/google-maps";
import {NgIf} from "@angular/common";

interface LatLng {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-map-with-radius',
  standalone: true,
  imports: [
    GoogleMap,
    MapCircle,
    MapMarker,
    NgIf
  ],
  templateUrl: './map-with-radius.component.html',
  styleUrl: './map-with-radius.component.scss'
})
export class MapWithRadiusComponent  implements OnInit {
  @Input() startPoint: LatLng | null = null;
  circleCenter: google.maps.LatLngLiteral | null = null;
  zoom = 11;

  circleRadius = 10000; // 10 km

  mapOptions: google.maps.MapOptions = {
    streetViewControl: false,
    zoomControl: true,
    mapTypeControl: false,
    fullscreenControl: false
  };

  markerOptions: google.maps.MarkerOptions = {
    position: this.circleCenter,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 10,
      fillColor: '#007AFF',
      fillOpacity: 1,
      strokeColor: '#ffffff',
      strokeWeight: 3,
    }
  };

  circleOptions: google.maps.CircleOptions = {
    strokeColor: '#007AFF',
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillColor: '#007AFF',
    fillOpacity: 0.35,
    center: this.circleCenter,
    radius: this.circleRadius
  };

  constructor() {}

  ngOnInit() {
    if (this.startPoint) {
      this.circleCenter = { lat: this.startPoint?.lat, lng: this.startPoint?.lng }
    }
  }

}
