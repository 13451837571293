import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-custom-button',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './custom-button.component.html',
  styleUrl: './custom-button.component.scss'
})
export class CustomButtonComponent {
  @Input() type: string = '';
  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Input() variant: string = 'primary'
  @Output() handleClick = new EventEmitter<string>();

  onCLick() {
    if (this.handleClick) {
      this.handleClick.emit();
    }
  }
}
