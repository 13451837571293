import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, map, Observable, switchMap} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class KeycloakAuth {
  private keycloakUrl = environment.keycloakUrl;
  private realm = environment.keycloakRealm;
  private clientId = environment.keycloakClientId;
  private clientSecret = environment.keycloakClientSecret;

  constructor(private http: HttpClient) {}

  private getAdminToken(): Observable<string> {
    const body = new URLSearchParams();
    body.set('client_id', this.clientId);
    body.set('client_secret', this.clientSecret);
    body.set('grant_type', 'client_credentials');

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post(`${this.keycloakUrl}/realms/${this.realm}/protocol/openid-connect/token`, body.toString(), { headers })
      .pipe(
        map((response: any) => response.access_token),
        catchError(error => {
          console.error('Failed to obtain admin token', error);
          throw error;
        })
      );
  }

  login(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const body = new URLSearchParams();
    body.set('client_id', environment.keycloakClientId);
    body.set('grant_type', 'password');
    body.set('client_secret', environment.keycloakClientSecret);
    body.set('username', username);
    body.set('password', password);

    const res =  this.http.post(this.keycloakUrl, body.toString(), { headers });

    return res
  }

  register(username: string, password: string, email: string): Observable<any> {
    return this.getAdminToken().pipe(
      switchMap(token => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        });

        const body = {
          username,
          email,
          enabled: true,
          credentials: [{
            type: 'password',
            value: password,
            temporary: false
          }]
        };

        return this.http.post(`${this.keycloakUrl}/admin/realms/${this.realm}/users`, body, { headers });
      }),
      catchError(error => {
        console.error('Failed to register user', error);
        throw error;
      })
    );

    // const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    //
    // const body = {
    //   username,
    //   email,
    //   enabled: true,
    //   credentials: [{
    //     type: 'password',
    //     value: password,
    //     temporary: false
    //   }]
    // };
    //
    // return this.http.post(`https://auth.neurun.com/auth/admin/realms/neurun/users`, body, { headers });
  }

  logout(): void {
    // Implement logout if necessary
  }
}
