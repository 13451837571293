import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http'
import { catchError, distinctUntilChanged, map } from 'rxjs/operators'
import { from, Observable, of, Subject, switchMap, throwError } from 'rxjs'
import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class OidcServiceService {
  private readonly redirectUrl = `${window.location.origin}/auth-callback`
  private readonly pAccessToken$ = new Subject<string | null>()
  readonly accessToken$ = this.pAccessToken$.asObservable().pipe(distinctUntilChanged())

  constructor(
    private httpClient: HttpClient,
  ) {
    this.pAccessToken$.next(this.accessToken)
  }

  get accessToken(): string | null {
    return window.localStorage.getItem('accessToken') || null
  }

  get isLoggedIn(): boolean {
    return !!this.accessToken
  }

  openAuthWindow(provider: string): Observable<string | null> {
    localStorage.setItem('auth_provider', provider);
    const authUrl = (environment as any)[provider]?.auth?.authorizationUrl;
    const clientId = (environment as any)[provider]?.auth?.client_id;
    const scope = (environment as any)[provider]?.auth?.scope;
    const authorizationUrl = `${authUrl}?response_type=code&client_id=${clientId}&redirect_uri=${this.redirectUrl}${scope ? `&scope=${scope}` : ''}`
    const authWindow = window.open(authorizationUrl, '_blank', 'popup=1, width=500, height=700')
    if (authWindow) {
      authWindow.opener = null;
    }

    const getAccessTokenCallback = (code: string) => {
      this.getNewAccessToken(code)
        .subscribe((data) => {
          if (data?.token) {
            this.setAccessToken(data?.token)
          }
        })
    }


    window.addEventListener('storage', function listener(event: StorageEvent) {
      if (event.storageArea !== localStorage) return
      if (event.key === 'code') {
        const code = event.newValue
        if (code) {
          getAccessTokenCallback(code)
        }
        window.removeEventListener('storage', listener)
      }
    })

    return this.accessToken$
  }

  private setAccessToken(token: string | null): void {
    if (token) {
      window.localStorage.setItem('accessToken', token)
    } else {
      window.localStorage.removeItem('accessToken')
    }
    this.pAccessToken$.next(token)
  }

  public getNewAccessToken(code: string): Observable<{ token: string, provider: string, refresh_token?: string } | null> {
    const provider = localStorage.getItem('auth_provider') || '';
    const { client_id, client_secret } = (environment as any )[provider].auth
    const options = {
      params: new HttpParams()
        .set('grant_type', 'authorization_code')
        .set('code', code)
        .set('client_id', client_id)
        .set('client_secret', client_secret)
        .set('redirect_uri', this.redirectUrl)
    }

    return this.httpClient
      .post<{ access_token: string, token_type: string, refresh_token?: string }>((environment as any )[provider].auth.tokenEndpoint, null, options)
      .pipe(map((data) => ({ token: `${data?.token_type} ${data?.access_token}`, provider: provider, refresh_token: data?.refresh_token || '' }) ))
      .pipe(catchError(() => of(null)))
  }

  public logout(token: string, provider: string) {

    const url = (environment as any )[provider].auth.deauthorizeUrl
    const httpOptions = {
      params: new HttpParams()
        .set('access_token', token)
    };


    return this.httpClient.post(url, undefined, httpOptions).pipe(catchError(this.handleError))

  }

  refreshToken(refreshToken: string): Observable<{ token: string, provider: string, refresh_token?: string } | null> {
    const provider = localStorage.getItem('auth_provider') || '';
    const { client_id, client_secret } = (environment as any)[provider].auth;

    const options = {
      params: new HttpParams()
        .set('grant_type', 'refresh_token')
        .set('client_id', client_id)
        .set('refresh_token', refreshToken)
        .set('client_secret', client_secret)
        .set('redirect_uri', this.redirectUrl)
    };

    return this.httpClient
      .post<{ access_token: string, token_type: string }>((environment as any)[provider].auth.tokenEndpoint, null, options)
      .pipe(
        map(({ access_token }) => ({ token: access_token, provider })),
        catchError(() => of(null))
      );
  }

  public logoutStrava(refreshToken: string): Observable<any> {
    return this.refreshToken(refreshToken).pipe(
      switchMap(response => {
        if (response?.token) {
          const url = environment.strava.auth.deauthorizeUrl;

          return from(axios.post(url, {}, {
            headers: {
              'Authorization': `Bearer ${response.token}`
            }
          }));
        } else {
          return throwError(() => new Error('Failed to refresh token'));
        }
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => errorMessage);
  }
}
