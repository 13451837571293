import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, NgIf } from "@angular/common";
import { CustomButtonComponent } from "../../../components/custom-button/custom-button.component";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { HeaderComponent } from "../../../components/header/header.component";
import { MatFormField, MatFormFieldModule, } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { SelectorOptions, User, UserFitnessInformation } from "../../../../types/models";
import { UserService } from "../../../services/user/user.service";
import { Router } from "@angular/router";
import { SpinnerServiceService } from "../../../services/spinner-service/spinner-service.service";
import { MatGridList, MatGridTile } from "@angular/material/grid-list";
import { NgxMaskDirective } from "ngx-mask";
import { USER_UNIT_SYSTEM } from "../../../../constants";
import { heightConverter } from "../../../helpers/heightConverter";
import { weightConverter } from "../../../helpers/weightConverter";
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";

@Component({
  selector: 'app-user-fitness',
  standalone: true,
  imports: [
    AsyncPipe,
    CustomButtonComponent,
    FormsModule,
    HeaderComponent,
    MatFormField,
    MatInput,
    NgIf,
    ReactiveFormsModule,
    MatGridList,
    MatGridTile,
    NgxMaskDirective,
    MatFormFieldModule,
    MatOption,
    MatSelect
  ],
  templateUrl: './user-fitness.component.html',
  styleUrl: './user-fitness.component.scss'
})
export class UserFitnessComponent implements OnInit {
  @ViewChild('hiddenSubmitButton') hiddenSubmitButton!: ElementRef<HTMLButtonElement>;
  user: User | undefined = undefined
  initialFormValues: any;
  userUnitSystem: string = 'metric';
  lengthSign: string = ' cm';
  heightMask: string = '';

  genderOptions: SelectorOptions[] = [
    { value: 'male', option: 'Male' },
    { value: 'female', option: 'Female' },
    { value: 'non_binary', option: 'Non-binary' }
  ];

  constructor(private userService: UserService, private router: Router, public spinnerService: SpinnerServiceService) {}

  fitnessForm = new FormGroup({
    age: new FormControl('', [UserFitnessComponent.ageValidator(12, 100)]),
    height: new FormControl('', [UserFitnessComponent.heightValidatorFactory(this)]),
    weight: new FormControl('', [UserFitnessComponent.weightValidatorFactory(this)]),
    avg_rest_hr: new FormControl(''),
    max_hr: new FormControl(''),
    vo_max: new FormControl(''),
    gender: new FormControl('', [Validators.required]),
    aboutMe: new FormControl('', [Validators.maxLength(1000)]),
  })

  normalizeValues(values: any): any {
    if (values) {
      return Object.keys(values).reduce((acc, key) => {
        acc[key] = String(values[key]);
        return acc;
      }, {} as any);
    }
  }

  hasFormChanged(): boolean {
    const normalizedInitialValues = this.normalizeValues(this.initialFormValues);
    const normalizedCurrentValues = this.normalizeValues(this.fitnessForm.value);
    return JSON.stringify(normalizedInitialValues) !== JSON.stringify(normalizedCurrentValues);
  }

  static ageValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && (isNaN(value) || value < min || value > max)) {
        return { ageOutOfRange: true };
      }
      return null;
    };
  }

  static heightValidatorFactory(component: UserFitnessComponent): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      let isValid = true;
      const userUnitSystem = component.userUnitSystem;
      if (!value) return null;

      if (userUnitSystem === 'metric') {
        isValid = !isNaN(value) && value >= 100 && value <= 250;
      } else if (userUnitSystem === 'imperial') {
        const converted = heightConverter(Number(value), 'metric');
        isValid = !isNaN(converted) && converted >= 100 && converted <= 250;
      }

      if (!isValid) {
        return { heightOutOfRange: true };
      }

      return null;
    };
  }

  static weightValidatorFactory(component: UserFitnessComponent): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      let isValid = true;
      const userUnitSystem = component.userUnitSystem; // Access the current value

      if (!value) return null;

      if (userUnitSystem === 'metric') {
        isValid = !isNaN(value) && value >= 40 && value <= 250;
      } else if (userUnitSystem === 'imperial') {
        const converted = weightConverter(Number(value), 'metric');
        isValid = !isNaN(converted) && converted >= 40 && converted <= 250;
      }

      if (!isValid) {
        return { weightOutOfRange: true };
      }
      return null;
    };
  }

  async ngOnInit() {
    const userUnitSystem = localStorage.getItem(USER_UNIT_SYSTEM);
    this.userUnitSystem = (userUnitSystem && JSON.parse(userUnitSystem)) || 'metric';
    if (this.userUnitSystem === 'imperial') {
      this.lengthSign = '"';
      this.heightMask = `0' 0"`;
    } else {
      this.lengthSign = ' cm';
      this.heightMask = `separator.2`;
    }

    this.userService.getUser().subscribe(response => {
      this.user = response

      const heightValue = this.userUnitSystem === 'imperial' ? heightConverter(Number(response?.fitness_information.height), this.userUnitSystem) : response?.fitness_information.height || '';
      const weightValue = this.userUnitSystem === 'imperial' ? weightConverter(Number(response?.fitness_information.weight), this.userUnitSystem) : response?.fitness_information.weight || '';

      this.fitnessForm.get('age')?.setValue(String(response?.fitness_information.age || ''));
      this.fitnessForm.get('height')?.setValue(String(heightValue));
      this.fitnessForm.get('weight')?.setValue(String(weightValue));
      this.fitnessForm.get('avg_rest_hr')?.setValue(String(response?.fitness_information.avg_rest_hr || ''));
      this.fitnessForm.get('max_hr')?.setValue(String(response?.fitness_information.max_hr || ''));
      this.fitnessForm.get('vo_max')?.setValue(String(response?.fitness_information.vo_max || ''));
      this.fitnessForm.get('gender')?.setValue(response?.gender ? response?.gender : '');
      this.fitnessForm.get('aboutMe')?.setValue(response?.about_me ? response?.about_me : '');

      this.initialFormValues = this.fitnessForm.value;
    })
  }

  onCustomButtonClick() {
    if (this.fitnessForm.valid && this.hasFormChanged()) {
      this.hiddenSubmitButton.nativeElement.click();
    }
  }

  onBackClick() {
    this.router.navigate(['/profile'])
  }

  onSubmit() {
    const heightData = this.userUnitSystem === 'imperial' ? heightConverter(Number(this.fitnessForm.value.height), 'metric') : Number(this.fitnessForm.value.height);
    const weightData = this.userUnitSystem === 'imperial' ? weightConverter(Number(this.fitnessForm.value.weight), 'metric') : Number(this.fitnessForm.value.weight);

    const data: UserFitnessInformation = {
      age: Number(this.fitnessForm.value.age) ?? 0,
      height: heightData,
      weight: weightData,
      avg_rest_hr: Number(this.fitnessForm.value.avg_rest_hr) ?? 0,
      max_hr: Number(this.fitnessForm.value.max_hr) ?? 0,
      vo_max: Number(this.fitnessForm.value.vo_max) ?? 0,
    }

    this.userService.updateUser({ fitness_information: data, gender: this.fitnessForm.value.gender ? this.fitnessForm.value.gender : '', about_me: this.fitnessForm.value.aboutMe ? this.fitnessForm.value.aboutMe : ''}).subscribe(() => {
      this.router.navigate(['/profile'])
    })
  }
}
