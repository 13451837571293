export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
export const REMOVE_RACE_WITHOUT_CONFIRMATION = 'remove-race-without-confirmation';
export const USER_UNIT_SYSTEM = 'user_unit_system';
export const USER_DATE_FORMAT = 'user_date_format';

export const AI_REQUESTS = [
  'Ask me about course or event info',
  'Assess my race day readiness',
  'Explore gear for event conditions',
  'Create training segments based on this event'
]

export const RACE_ID = 'race_id';
export const SCREEN_SMALL_SIZE = 1365;
export const RACE_LOGO_URL = 'race_logo_url';
export const AI_REQUEST_TIMEOUT = 30000
