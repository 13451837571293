<div class="container">
  <div *ngIf="isMobile" class="header">
    <h1 class="title">Profile</h1>
  </div>
  <div class="user-info">
    <div (click)="onImageClick()">
      <img [src]="avatar || 'assets/images/default-avatar.png'" alt="Avatar" width='72' height="72" style="border-radius: 50%" />
      <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/png, image/gif, image/jpeg" hidden />
    </div>
    <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 8px">
      <span style="font-weight: 600; font-size: 19px">Hi, {{ user?.display_name || user?.first_name }}!</span>
    </div>
  </div>
  <div style="padding: 0 16px; display: flex; flex-direction: column; gap: 24px">
    <div>
      <p class="section-title">Account Info</p>
      <div class="section-wrapper">
        <div class="sub-section-wrapper" (click)="onSectionClick('user-name')">
          <span class="sub-section-title">Name</span>
          <img src="assets/images/keyboard_arrow_up.svg" alt="Arrow" />
        </div>
        <mat-divider></mat-divider>
        <div class="sub-section-wrapper" (click)="onSectionClick('user-email')">
          <span class="sub-section-title">Email & Password</span>
          <img src="assets/images/keyboard_arrow_up.svg" alt="Arrow" />
        </div>
      </div>
    </div>
    <div>
      <p class="section-title">Personal Info</p>
      <div class="section-wrapper">
        <div class="sub-section-wrapper" (click)="onSectionClick('user-fitness')">
          <span class="sub-section-title">Fitness Information</span>
          <img src="assets/images/keyboard_arrow_up.svg" alt="Arrow" />
        </div>
        <mat-divider></mat-divider>
        <div class="sub-section-wrapper" (click)="onSectionClick('user-connected-accounts')">
          <span class="sub-section-title">Connected Accounts</span>
          <img src="assets/images/keyboard_arrow_up.svg" alt="Arrow" />
        </div>
      </div>
    </div>
    <div>
      <p class="section-title">Preferences</p>
      <div class="section-wrapper">
        <div class="sub-section-wrapper" (click)="onSectionClick('user-ai-settings')">
          <span class="sub-section-title">AI Settings</span>
          <img src="assets/images/keyboard_arrow_up.svg" alt="Arrow" />
        </div>
        <mat-divider></mat-divider>
        <div class="sub-section-wrapper" (click)="onSectionClick('user-units')">
          <span class="sub-section-title">Units</span>
          <img src="assets/images/keyboard_arrow_up.svg" alt="Arrow" />
        </div>
        <mat-divider></mat-divider>
<!--        <div class="sub-section-wrapper" (click)="onSectionClick('user-notifications')">-->
<!--          <span class="sub-section-title">Notifications</span>-->
<!--          <img src="assets/images/keyboard_arrow_up.svg" alt="Arrow" />-->
<!--        </div>-->
      </div>
    </div>
    <div>
      <div class="sub-section-wrapper">
        <span class="sub-section-title">Support & Feedback</span>
      </div>
      <div class="sub-section-wrapper">
        <span class="sub-section-title" style="color: #6271FF" (click)="onLogoutClick()">Logout</span>
      </div>
      <div class="sub-section-wrapper">
        <span class="sub-section-title" style="color: #FF3B30" (click)="onDeleteAccountClick()">Delete Account</span>
      </div>
    </div>
  </div>
</div>
