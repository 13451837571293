<div class="container">
  <div class="wrapper">
    <div style="margin-bottom: 24px">
      <span class="title">Let’s Help You <br /> Find a Race</span>
    </div>
    <div>
      <span class="subtitle">
        You can search for a specific race, <br /> or for any desired location, <br /> distance, or course feature.
      </span>
    </div>
    <div style="margin-bottom: 8px; margin-top: 32px;">
      <app-search-input (searchChange)="onSearchChange($event)" placeholder="" theme="light"></app-search-input>
    </div>
    <div>
      <span class="subtitle2">
        Example: Trail marathons in France along rivers
      </span>
    </div>
    <img src="assets/images/mountains.svg" alt="Mountains" class="image" />
    <button class="manual-add-button" (click)="onManualAdd()">
      <span style="font-weight: 200; font-size: 16px; opacity: 0.9">or, enter race data manually</span>
    </button>
  </div>
</div>
