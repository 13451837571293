import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderComponent } from "../../components/header/header.component";
import { ActivatedRoute, Router } from "@angular/router";
import { RaceService } from "../../services/race/race.service";
import { ChatErrorMessage, ChatMessage, Race } from "../../../types/models";
import { ChatService } from "../../services/chat/chat.service";
import { AI_REQUEST_TIMEOUT, AI_REQUESTS } from "../../../constants";
import { FormsModule } from "@angular/forms";
import { DatePipe, NgClass, NgForOf, NgIf, NgOptimizedImage } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatChip, MatChipSet } from "@angular/material/chips";
import { KeycloakService } from "keycloak-angular";
import { OidcServiceService } from "../../services/oidcService/oidc-service.service";
import { CustomButtonComponent } from "../../components/custom-button/custom-button.component";
import { ScreenResizeService } from "../../services/screenResive/screen-resize.service";

interface Messages {
  text: string,
  is_system: boolean,
  loading?: boolean,
  sent_at: Date,
  options?: string[],
  is_options_message?: boolean,
  label?: string,
  gearOptions?: {
    text: string,
    link: string
  }[],
  link_accounts_options?: {
    name: string,
    logo: string
  }[]
}

@Component({
  selector: 'app-race-ai-chat',
  standalone: true,
  imports: [
    HeaderComponent,
    FormsModule,
    NgClass,
    NgForOf,
    NgOptimizedImage,
    MatIcon,
    DatePipe,
    NgIf,
    MatProgressSpinner,
    MatChip,
    MatChipSet,
    CustomButtonComponent
  ],
  templateUrl: './race-ai-chat.component.html',
  styleUrl: './race-ai-chat.component.scss'
})
export class RaceAiChatComponent implements OnInit, OnDestroy {
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  @Input() request_index: number | null = null;
  @Input() isMobile: boolean = true;
  raceId: string | null = '';
  race: Race | null = null;
  requestIndex: number | null = null;
  messages: Messages[] = [];
  userMessage: string = '';
  date: Date | string = '';
  chatError: string | null = '';
  isChatLoading: boolean = false;
  filteredAiPrompts: string[] = [];
  hasRunkeeperTokenProcessed = false;
  AIRequestError: boolean = false;
  private loadingMessageTimeout: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private raceService: RaceService,
    private chatService: ChatService,
    private readonly keycloakService: KeycloakService,
    private readonly oidcService: OidcServiceService,
    private screenSizeService: ScreenResizeService
  ) {}

  async ngOnInit() {
    const token = await this.keycloakService.getToken();
    if (!token) {
      await this.keycloakService.login()
    }

    const isAiChatPage = this.route.snapshot.routeConfig?.path?.includes('ai-chat');

    this.screenSizeService.getIsMobile().subscribe(isMobile => {
      if (isAiChatPage && !isMobile) {
        this.router.navigate(['my-races/race', this.raceId], { queryParams: { request_index: this.requestIndex } });
        return;
      }
    })

    this.initializeRaceId();
    if (this.raceId) {
      this.loadRaceData();
    }



    if (this.requestIndex) {
      // @ts-ignore
      this.filteredAiPrompts = AI_REQUESTS.filter((item, index) => index !== this.requestIndex - 1);
    }

    window.addEventListener('storage', (e) => this.onRunkeeperToken(e))
  }

  onRunkeeperToken(event: StorageEvent) {
    if (this.hasRunkeeperTokenProcessed) return;
    if (event.storageArea !== localStorage) return
    if ((event.key === 'runkeeper_token' || event.key === 'strava_token') && event.newValue) {
      const runkeeperData = JSON.parse(event.newValue);
      if (runkeeperData.token) {
        const accountType = event.key.replace('_token', '');
        this.chatService.sendLinkAccountMessage(runkeeperData.token, accountType);
        this.hasRunkeeperTokenProcessed = true;
      }
      window.removeEventListener('storage', this.onRunkeeperToken)
    }
  }

  ngOnDestroy() {
    this.chatService.disconnect();
    window.removeEventListener('storage', this.onRunkeeperToken);
  }

  private initializeRaceId() {
    this.route.paramMap.subscribe((params) => {
      this.raceId = params.get('race_id');
      this.requestIndex = Number(this.route.snapshot.paramMap.get('request')) || this.request_index || null;
    });
  }

  private loadRaceData() {
    this.raceService.getRaceById(this.raceId!).subscribe((response) => {
      this.race = response;
      this.initializeChat();
    });
  }

  private initializeChat() {
    this.chatService.init(this.raceId!).then(() => {
      this.isChatLoading = true;
      this.chatService.onConnectionReady().subscribe(() => {
        this.isChatLoading = false;
        this.loadChatMessages();
        this.loadChatHistory();
        this.subscribeToErrors()
      });
    });
  }

  private loadChatMessages() {
    this.chatService.getMessages().subscribe((message: ChatMessage) => {
      this.processIncomingMessage(message);
    });
  }

  private loadChatHistory() {
    if (this.requestIndex !== null) {
      this.chatService.getChatHistory().subscribe((data) => {
        this.processChatHistory(data);
      });
    }
  }

  private subscribeToErrors() {
    this.chatService.getError().subscribe((message: ChatErrorMessage) => {
      this.handleError(message);
    });
  }

  private processIncomingMessage(message: ChatMessage) {
    this.isChatLoading = false;

    if (this.loadingMessageTimeout) {
      clearTimeout(this.loadingMessageTimeout);
      this.loadingMessageTimeout = null;
    }

    const lastMessage = this.messages.find((msg) => msg.loading && msg.is_system);

    if (lastMessage) {
      this.updateLastMessage(lastMessage, message);
    } else {
      this.addNewMessage(message);
    }

    this.scrollToBottom();
  }

  private updateLastMessage(lastMessage: Messages, message: ChatMessage) {
    lastMessage.loading = false;
    lastMessage.text = message.data?.message;
    if (message.data?.options) {
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: false,
        sent_at: new Date(),
        options: message.data?.options || [],
      })
    }
    this.addGearMessages(message);
    this.addLinkAccountsMessage(message);
  }

  private addNewMessage(message: ChatMessage) {
    this.messages.push({
      text: message.data?.message,
      is_system: true,
      sent_at: new Date(),
    });
    if (message?.data?.options) {
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: false,
        sent_at: new Date(),
        options: message.data?.options || [],
      })
    }

    this.addGearMessages(message);
    this.addLinkAccountsMessage(message);
  }

  private addLinkAccountsMessage(message: ChatMessage) {
    if (message.data?.link_accounts?.length) {
      const linkAccountsOptions = message.data.link_accounts.map(i => ({ name: i.name, logo: `/assets/images/${i.name}-logo.svg` }))
      this.messages.push({
        text: message.data.message,
        sent_at: new Date(),
        link_accounts_options: linkAccountsOptions,
        is_system: true
      })
    }
  }

  private addGearMessages(message: ChatMessage) {
    if (message.data?.gears?.length) {
      message.data.gears.forEach((gear) => {
        const gearOptions = [{ text: `Explore ${gear.brand}`, link: gear?.link }];
        this.messages.push({
          text: gear.description,
          label: gear.name,
          sent_at: new Date(),
          gearOptions,
          is_system: true,
        });
      });
    }
  }

  private processChatHistory(data: ChatMessage[]) {
    if (data?.length) {
      const formattedMessages = this.formatChatHistory(data);
      this.messages.push(...formattedMessages);
      this.sendInitialMessage();
    } else if (this.requestIndex) {
      this.sendInitialMessage();
    }
  }

  private formatChatHistory(data: ChatMessage[]) {
    return data.flatMap((i) => {
      const baseMessage = {
        text: i.data?.message,
        is_system: i.is_system,
        loading: false,
        sent_at: new Date(i.sent_at),
      };
      const gearMessages = i.data?.gears?.map((gear) => ({
        text: gear.description,
        label: gear.name,
        sent_at: new Date(),
        gearOptions: [{ text: `Explore ${gear.brand}`, link: gear.link }],
        is_system: true,
      }));
      return gearMessages ? [baseMessage, ...gearMessages] : baseMessage;
    });
  }

  private sendInitialMessage() {
    this.chatService.sendMessage(AI_REQUESTS[this.requestIndex! - 1]);
    this.messages.push({
      text: AI_REQUESTS[this.requestIndex! - 1],
      is_system: false,
      sent_at: new Date(),
    });
    this.scrollToBottom();
    this.addLoadingMessage();
  }

  private addLoadingMessage() {
    this.messages.push({ text: '', is_system: true, loading: true, sent_at: new Date() });

    this.loadingMessageTimeout = setTimeout(() => {
      this.removeLoadingMessage();
      this.AIRequestError = true;
    }, AI_REQUEST_TIMEOUT);
  }

  private handleError(message: ChatErrorMessage) {
    this.isChatLoading = false;
    this.chatError = message.error || 'Something went wrong.';
    this.removeLoadingMessage();
  }

  private removeLoadingMessage() {
    const lastMessage = this.messages.find((msg) => msg.loading && msg.is_system);
    if (lastMessage) {
      this.messages = this.messages.filter((msg) => msg !== lastMessage);
    }

    if (this.loadingMessageTimeout) {
      clearTimeout(this.loadingMessageTimeout);
      this.loadingMessageTimeout = null;
    }
  }

  scrollToBottom(): void {
    setTimeout(() => {
      this.chatContainer.nativeElement.scrollTo({
        top: this.chatContainer.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  onOptionClick(option: string) {
    this.chatService.sendMessage(option);

    const lastMessageIndex = this.messages.length - 1;
    const lastMessage = this.messages[lastMessageIndex];

    if (lastMessage.options) {
      delete lastMessage.options;
    }

    this.messages[lastMessageIndex] = lastMessage;

    this.messages.push({ text: option, is_system: false, sent_at: new Date() });
    this.scrollToBottom();
    this.addLoadingMessage();
  }

  sendMessage() {
    if (this.userMessage.trim()) {
      this.messages.push({ text: this.userMessage, is_system: false, sent_at: new Date() });
      this.chatService.sendMessage(this.userMessage);
      this.userMessage = '';
      this.scrollToBottom();
      this.addLoadingMessage();

      if (this.loadingMessageTimeout) {
        clearTimeout(this.loadingMessageTimeout);
        this.loadingMessageTimeout = null;
      }
    }
  }

  onBackClick() {
    this.chatService.disconnect();
    this.router.navigate(['my-races/race', this.raceId]);
  }

  onSettingsClick() {
    this.router.navigate(['profile/user-ai-settings']);
  }

  onAiRequestClick(index: number) {
    const message = this.filteredAiPrompts[index - 1];
    this.requestIndex = index;
    this.chatService.sendMessage(message);
    this.messages.push({ text: message, is_system: false, sent_at: new Date() });
    this.scrollToBottom();
    this.addLoadingMessage();
  }

  onFitnessClick(provider: string) {
    this.oidcService.openAuthWindow(provider)
  }

  regenerateResponse() {
    this.AIRequestError = false;
    const message = this.messages[this.messages.length - 1];
    this.chatService.sendMessage(message.text);
    this.messages.push({ text: message.text, is_system: false, sent_at: new Date() });
    this.scrollToBottom();
    this.addLoadingMessage();
  }
}
