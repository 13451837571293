import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatFormField, MatPrefix} from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatIconButton } from "@angular/material/button";
import {FormsModule} from "@angular/forms";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-search-input',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    MatIconButton,
    FormsModule,
    MatIcon,
    MatPrefix
  ],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss'
})
export class SearchInputComponent {
  searchText: string = '';
  @Input() placeholder: string = ''
  @Input() value: string = ''
  @Input() theme: string = 'dark'
  @Output() searchChange = new EventEmitter<string>();


  onSearchChange() {
    this.searchChange.emit(this.searchText);
  }
}
