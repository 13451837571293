<div class="page-container">
  <app-header title="Email & Password" (handleBackClick)="onBackClick()"></app-header>
  <div *ngIf="spinnerService.visibility | async; else content" class="loading">
    <div class="loader"></div>
  </div>
  <ng-template #content>
    <div class="form-container">
      <form [formGroup]="emailForm" (ngSubmit)="onSubmit()">
        <div>
          <span class="input-label">Email</span>
          <mat-form-field>
            <input matInput type="email" id="email" formControlName="email" autocomplete="off">
          </mat-form-field>
        </div>
        <div>
          <span class="input-label">Update Password</span>
          <div style="margin-top: 8px" (click)="updatePassword()">
            <span class="action-button">Click here to update your password</span>
          </div>
        </div>
        <button type="submit" style="display: none" #hiddenSubmitButton></button>
      </form>
      <div class="submit-button-container">
        <app-custom-button type="submit" title="Save" variant="primary" [disabled]="!emailForm.valid" (click)="onSubmit()"></app-custom-button>
      </div>
    </div>
  </ng-template>
</div>
