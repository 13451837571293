<div class="container" [ngStyle]="{'background': whiteHeader ? '#FFFFFF' : 'linear-gradient(to right, #4D58BD, #617FF5)'}">
  <div *ngIf="whiteHeader; else defaultHeader" class="wrapper">
    <div *ngIf="siteLogo && siteLogo !== 'undefined'; else emptyLogoBlock">
      <img [src]="siteLogo" alt="Race logo" style="width: 92px; height: 92px; object-fit: contain" />
    </div>
    <ng-template #emptyLogoBlock>
      <div style="width: 92px; height: 92px"></div>
    </ng-template>
    <img src="assets/images/neurun-ai-logo.svg" alt="Neuran AI" />
  </div>
  <ng-template #defaultHeader>
    <div class="wrapper">
      <button class="back-btn" (click)="onBackClick()">
        <div style="display: flex; align-content: center; justify-content: center">
          <img src="assets/images/arrow-back-thin.svg" alt="Arrow back" />
          <span class="back-title">Back</span>
        </div>
      </button>
      <span class="title">{{title}}</span>
      <div *ngIf="showAdditionalAction; else emptyBlock">
        <button class="back-btn" (click)="onRightButtonClick()">
          <div style="display: flex; align-content: center; justify-content: center">
          <span style="display: contents" *ngIf="type === 'edit'">
            <img src="assets/images/pencil.svg" alt="Pencil" />
            <span class="back-title">Edit</span>
          </span>
            <span *ngIf="type === 'settings'" style="width: 50px; text-align: end">
            <img src="assets/images/settings-icon.svg" alt="Settings" />
          </span>
          </div>
        </button>
      </div>
      <ng-template #emptyBlock>
        <div style="width: 66px; height: 30px"></div>
      </ng-template>
    </div>
  </ng-template>
</div>
