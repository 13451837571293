import {Component, ElementRef, ViewChild} from '@angular/core';
import {AuthHeaderComponent} from "../../components/auth-header/auth-header.component";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {CustomButtonComponent} from "../../components/custom-button/custom-button.component";
import {NgIf} from "@angular/common";
import {Router} from "@angular/router";

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    AuthHeaderComponent,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    CustomButtonComponent,
    NgIf
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  @ViewChild('hiddenSubmitButton') hiddenSubmitButton!: ElementRef<HTMLButtonElement>;
  title: string = 'Forgot Password';
  subTitle: string = 'Don’t worry, it happens. Enter the email associated with your account and we’ll send you a link to reset your password.';
  isEmailSent: boolean = false;

  constructor(private router: Router) {}

  emailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  })

  onSubmit() {
    this.title = 'Email Sent';
    this.subTitle = 'If there’s an account associated with the email you entered, we’ve sent a link to reset your password.';
    this.isEmailSent = true;
  }

  onCustomButtonClick() {
    if (this.isEmailSent) {
      this.router.navigate(['/login'])
      return;
    }
    if (this.emailForm.valid) {
      this.hiddenSubmitButton.nativeElement.click();
    }
  }
}
