import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'distanceConverter'
})
export class DistanceConverterPipe implements PipeTransform {

  transform(value: number, unit: string): string {
    if (!value) {
      return '';
    }

    if (unit === 'metric') {
      return `${value} km`;
    } else if (unit === 'imperial') {
      return (value / 1.60934).toFixed(2) + ' mi';
    } else {
      return value.toString();
    }
  }
}
