import { Component, Input, OnInit } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexMarkers,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  NgApexchartsModule, ApexGrid
} from 'ng-apexcharts';
import { TrackCoordinates } from '../../../types/models';

@Component({
  selector: 'app-elevation-profile',
  standalone: true,
  imports: [
    NgApexchartsModule
  ],
  templateUrl: './elevation-profile.component.html',
  styleUrls: ['./elevation-profile.component.scss']
})
export class ElevationProfileComponent implements OnInit {
  @Input() coordinates: TrackCoordinates[] = [];
  @Input() units: string = 'metric';

  public series: ApexAxisChartSeries = [];
  public chart!: ApexChart;
  public dataLabels!: ApexDataLabels;
  public markers!: ApexMarkers;
  public title!: ApexTitleSubtitle;
  public fill!: ApexFill;
  public yaxis!: ApexYAxis;
  public xaxis!: ApexXAxis;
  public tooltip!: ApexTooltip;
  public grid!: ApexGrid;

  constructor() {}

  ngOnInit() {
    this.initChartData();
  }

  haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const R = 6371; // Earth radius in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  private calculateSlope(startIndex: number, endIndex: number, points: TrackCoordinates[]): number {
    const startPoint = points[startIndex];
    const endPoint = points[endIndex];
    const distance = this.haversineDistance(startPoint.lat, startPoint.lon, endPoint.lat, endPoint.lon);

    if (distance === 0) return 0; // Avoid division by zero
    const elevationChange = (endPoint.ele ?? 0) - (startPoint.ele ?? 0);

    return 0.1 * (elevationChange / distance);
  }

  private ramerDouglasPeucker(points: TrackCoordinates[], epsilon: number): TrackCoordinates[] {
    return points;
  }

  private mapPointsToChartData(points: TrackCoordinates[]): { x: number, y: number, slope?: number }[] {
    let cumulativeDistance = 0;
    const chartData = [];

    const distanceConversionFactor = this.units === 'imperial' ? 0.621371 : 1;
    const elevationConversionFactor = this.units === 'imperial' ? 3.28084 : 1;

    const simplifiedPoints = this.ramerDouglasPeucker(points, 20);

    for (let i = 0; i < simplifiedPoints.length; i++) {
      let slope = 0;

      if (i > 0) {
        const prevPoint = simplifiedPoints[i - 1];
        const currentPoint = simplifiedPoints[i];
        const distance = this.haversineDistance(prevPoint.lat, prevPoint.lon, currentPoint.lat, currentPoint.lon);
        cumulativeDistance += distance;

        slope = this.calculateSlope(i - 1, i, simplifiedPoints);
      }

      const distanceInUnits = cumulativeDistance * distanceConversionFactor;
      const elevationInUnits = simplifiedPoints[i].ele * elevationConversionFactor;

      chartData.push({
        x: Math.round(distanceInUnits * 1e2) / 1e2,
        y: Math.round(elevationInUnits * 1e2) / 1e2,
        slope: Math.round(slope * 1e2) / 1e2
      });
    }

    return chartData;
  }

  public initChartData(): void {
    const chartData = this.mapPointsToChartData(this.coordinates);

    this.series = [{
      name: 'Elevation',
      data: chartData
    }];

    this.grid = {
      show: false
    };

    this.chart = {
      type: 'area',
      height: 175,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
    };

    this.dataLabels = {
      enabled: false
    };

    this.markers = {
      size: 0
    };

    this.title = {
      text: 'Elevation Profile',
      align: 'left'
    };

    this.yaxis = {
      min: (val: number) => val - val,
      axisBorder: {
        show: true,
        color: '#C3C3C3'
      },
      labels: {
        formatter: (val: number): string | string[] => {
          return this.units === 'imperial' ? `${val} ft` : `${val} m`;
        }
      }
    };

    this.xaxis = {
      tickAmount: 4,
      axisBorder: {
        show: true,
        color: '#C3C3C3'
      },
    };

    this.tooltip = {
      shared: false,
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const point = w.globals.series[seriesIndex][dataPointIndex];
        const slope = w.globals.initialSeries[seriesIndex].data[dataPointIndex].slope;

        const slopeOutput = `Grade: <strong style="margin-left: 25px">${slope.toFixed(1)}%</strong>`;
        const elevationOutput = this.units === 'imperial'
          ? `${point.toFixed(1)} ft`
          : `${point.toFixed(1)} m`;

        return `
      <span style="padding: 10px; background: rgba(255, 255, 255, 0.7)!important">
        Elevation: <strong>${elevationOutput}</strong><br/>
        ${slopeOutput}
      </span>`;
      },
      x: {
        formatter: (val: number): string => {
          return this.units === 'imperial' ? `${val.toFixed(2)} mi` : `${val.toFixed(2)} km`;
        }
      },
    };
  }
}
