import {AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Input, ViewChild} from '@angular/core';

interface LatLng {
  lat: number;
  lng: number;
}

@Component({
  selector: 'app-map-immersive-view',
  standalone: true,
  imports: [],
  templateUrl: './map-immersive-view.component.html',
  styleUrl: './map-immersive-view.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MapImmersiveViewComponent implements AfterViewInit {
  @Input() path: LatLng[] = [];
  @Input() startPoint: LatLng | null = null;
  @ViewChild('map3d') map3dElement!: ElementRef;
  private startIcon: string = ''

  constructor() {
    this.startIcon = '../../../assets/images/start-marker.svg'
  }

  ngAfterViewInit(): void {
    const map3d = this.map3dElement.nativeElement;
    map3d.setAttribute('center', `${this.startPoint?.lat},${this.startPoint?.lng}`);

    const polyline = document.querySelector('gmp-polyline-3d');
    if (polyline) {
      customElements.whenDefined(polyline.localName).then(() => {
        (polyline as any).coordinates = this.path;
        // const startIcon = {
        //   url: this.startIcon,
        //   scaledSize: new google.maps.Size(42, 42),
        //   origin: new google.maps.Point(0, 0),
        //   anchor: new google.maps.Point(16, 32),
        // };
        // new google.maps.Marker({
        //   position: this.path[0],
        //   map: map3d,
        //   icon: startIcon,
        // });
      });
    }
  }

  addCustomMarkers(map3d: any) {
    // Create marker 1
    const marker1 = document.createElement('gmp-marker');
    marker1.setAttribute('position', '38.703542,-121.170258');
    marker1.setAttribute('title', 'Marker 1');
    marker1.setAttribute('icon', this.startIcon);

    // Create marker 2
    // const marker2 = document.createElement('gmp-marker');
    // marker2.setAttribute('position', '37.7749,-122.4194,100');
    // marker2.setAttribute('title', 'Marker 2');
    // marker2.setAttribute('icon', 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png');

    new google.maps.Marker({
      position: this.path[0],
      icon: this.startIcon,
    });

    map3d.appendChild(marker1);
    // map3d.appendChild(marker2);
    console.log('marker1', marker1);
    console.log('Custom markers added');
  }
}
