<div>
  <apx-chart
    [series]="series"
    [chart]="chart"
    [dataLabels]="dataLabels"
    [markers]="markers"
    [fill]="fill"
    [yaxis]="yaxis"
    [xaxis]="xaxis"
    [tooltip]="tooltip"
    [autoUpdateSeries]="true"
    [grid]="grid"
    [colors]="['#6271FF']"
  ></apx-chart>
</div>
