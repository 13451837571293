import { Injectable } from '@angular/core';
import { MapGeocoder } from '@angular/google-maps';
import {Coordinates} from "../../../types/models";
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
  constructor(private geocoder: MapGeocoder) {}

  getPlaceByCoordinates(coordinates: Coordinates): Observable<{ formatted_address: string }[]> {
    return this.geocoder.geocode({
      location: { lat: coordinates.lat, lng: coordinates.lon}
    }).pipe(
      map(({ results }) => {
        if (results.length > 0) {
          return results
        } else {
          throw new Error('No results found');
        }
      })
    )
  }
}
