<div style="height: 100vh; display: flex; flex-direction: column">
  <div class="container">
    <app-auth-header
      [title]="title"
      [subTitle]="subTitle"
    ></app-auth-header>
    <form *ngIf="!isEmailSent" [formGroup]="emailForm" (ngSubmit)="onSubmit()" style="margin-top: 40px">
      <div>
        <span class="input-label">Email Address</span>
        <mat-form-field>
          <input matInput id="email" formControlName="email" placeholder="example@neurun.com">
        </mat-form-field>
      </div>
      <span class="action-button">Can’t access your email?</span>
      <button type="submit" style="display: none" #hiddenSubmitButton></button>
    </form>
  </div>
  <div style="margin-top: auto; padding: 16px">
    <app-custom-button type="submit" [title]="isEmailSent ? 'Go Back to Login' : 'Submit'" variant="primary" [disabled]="!emailForm.valid" (click)="onCustomButtonClick()"></app-custom-button>
  </div>
</div>
